.dialogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.warningDialog {
    border: none;
    box-shadow: 10px 6px 70px rgba(0, 0, 0, .5);
    border-radius: 10px;
    padding: 4rem;
    font-weight: bolder;
    font-size: 1.5rem;
}

.dialogButtonRow {
    display: flex;
    gap: 1rem;
}

.dangerBtn {
    font-size: 1rem;
    background-color: #f70e19;
    padding: .5rem;
    border: none;
    border-radius: 5px;
    color: white;

    &:hover {
        background-color: #ca1119;
    }
}