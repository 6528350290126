.listrow{
    background-color: white;
    padding: 0.75%;
    border-width: 2px;
    border: 1px solid #5a9ba9;
    margin-bottom: 1%;
  border-radius: 11px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(90,155,169,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(90,155,169,1);
  box-shadow: 0px 0px 3px 0px rgba(90,155,169,1);
  align-items: center;
}
.claimBtn {
    width:100%;
    color:#fff !important;
    background-color:#1baf2f !important;
    border-color:#1baf2f !important;
    font-weight:700
  }
  .claimBtn:hover {
    background-color:#158724 !important;
    border-color:#158724 !important;
  }
  .unclaimBtn {
    width:100%;
    color:#952424 !important;
    background-color:#f8fff2 !important;
    border-color:#f8fff2 !important;
    font-weight:700
  }
  .unclaimBtn:hover {
    background-color:#d8e0d0 !important;
    border-color:#d8e0d0 !important;
    color:#00647c !important;
  }

  .listRowclaimed{
    background-color: #00647c !important;
    color:white !important;
    font-weight: bold !important;
  }
  .whitelink{
    
    color:white;
    font-weight: bold;
  }
  .claimSection{
    flex-direction: column;
  }
  .listRowClaimedOthers{
    background-color: #e8e8e8;
  }