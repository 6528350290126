body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8fff2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-primary{
  background-color: #00647c !important;
  border-color: #00647c !important;
}
.btn-primary:hover{
  background-color: #003a50 !important;
  border-color: #003a50 !important;
}
.btn-primary:active{
  background-color: #003a50 !important;
  border-color: #003a50 !important;
}
.btn-outline-primary{
  border-color: #00647c;
  color: #00647c;
}
.btn-outline-primary:hover{
  border-color: #003a50;
  background-color: #003a50;
}
.btn-outline-primary:active{
  border-color: #00647c !important;
  background-color: #00647c !important;
}
.innerContent{
  margin-top: 0 !important;
}
.header-column{
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%;
}
.fullWidthBtn{
  min-width: 10rem;
}