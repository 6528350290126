
.listRow{
    background-color: white;
    padding: 0.75%;
    border-width: 2px;
    border: 1px solid #5a9ba9;
    margin-bottom: 1%;
  border-radius: 11px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(90,155,169,1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(90,155,169,1);
  box-shadow: 0px 0px 3px 0px rgba(90,155,169,1);
  align-items: center;
}
.userName{
    font-weight: bold;
}
.header-date{
    font-weight: bold;
}
.fullWidth{
    width: 100%;
}