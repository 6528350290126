.eventCard.card {
    margin: 1rem;
    flex-basis: 92%;
    padding: 0;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    max-width:21rem;
  }
  
  .eventCard.card:hover,
  .eventCard.card:focus,
  .eventCard.card:active {
    color: #0070f3;
    border-color: #0070f3;
  }
  
  .eventCard.card h3 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
  }
  
  .eventCard.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .card-body{
    display: flex;
    flex-direction: column;
    gap: .75rem;
  }
  .card-subtitle{
    align-self: center;
  }
  .card-title{
    align-self: center;
  }
